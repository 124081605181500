import React, { useState } from 'react';
import "./ProjectPane.css";

function ProjectPane({data, handleClick, row}) {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="project-pane">
      <div className="project-container" onClick={() => handleClick(data, row)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div className="project-pane" style={{opacity: `${isHovered ? .8 : 1}`}}>
          <img className="project-pane__image" src={data.noHoverImage}/>
          <span className="project-pane__title">{data.title}</span>
        </div>
      </div>  
    </div>
  );
}

export default ProjectPane;
