import './Education.css'
import UCSD_SEAL_IMG from "./images/UCSD_Seal.png"

function Education () {
    return (
        <div className='education'>
            <img className="education-seal" src={UCSD_SEAL_IMG}/>
            <div className="education-container">
                <div className="education-school-header">
                    <h1 className="education-school">University of California San Diego</h1>
                    <span className="education-date">09/2016 - 06/2020</span>
                </div>
                <div className="education-degree-container">
                    <h3 className="education-degree">B.S. in Mathematics and Computer Science</h3>
                </div>
                <div className='education-description'>
                    <p className='education-description-text'></p>
                </div>
            </div>
        </div>
    );
}

export default Education;