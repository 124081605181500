import './Work.css'
import Job from './Job'

const ANNOTATIONS_DATA = {
    company: "Bepom Inc",
    timeRange: "11/2023 - Now",
    title: "Software Engineer II",
    description: "•	Design and implement scalable cloud-based architectures using AWS services, including Lambda, S3, and RDS, resulting in optimized performance and reduced costs for clients.\n•	Work closely with clients to translate business requirements into technical specifications, delivering custom software solutions that align with their vision and goals."
}

const AMAZON_DATA = {
    company: "Amazon",
    timeRange: "09/2020 - 02/2023",
    title: "Software Development Engineer I",
    description: " • Developed and launched Luxury Stores tablet app experience to customers in NA and EU\n• Internationalized email confirmations, fees infrastructure and web/app experiences, building scalable solutions and enforcing best practices\n• Independently designed and developed internal tooling hub on Native  AWS, facilitating automation and improving developer experience.\n• Routinely lead maintenance of documentation and SOPs org-wide."
}

const AMAZON_INTERN_DATA = {
    company: "Amazon",
    timeRange: "06/2019 - 09/2019",
    title: "Software Development Engineer Intern",
    description: "• Worked on Alexa devices team implementing production ready support for new photo related utterances.\n• Designed and developed in java, maintaining useful and accurate documentation."
}

function Work () {
    return (
        <div className="work-container">
            <h1 className='work-history-title'>Work History</h1>
            <Job jobData={ANNOTATIONS_DATA}/>
            <hr className='work-section-split'/>
            <Job jobData={AMAZON_DATA}/>
            <hr className='work-section-split'/>
            <Job jobData={AMAZON_INTERN_DATA}/>
        </div>
    );
}

export default Work;