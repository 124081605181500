import './App.css';
import ProjectGrid from './ProjectGrid';
import Bio from './Bio'
import Education from './Education';
import FunMath from './FunMath';
import Work from './Work';

function App() {
  return (
    <div className="App">
      <header>
        <div className="App-header">
          <div className="App-title">
            Alexander Gibson
          </div>
          <div className="App-links">
            <div id="projects">
              <a href="#projects">projects</a>
            </div>
            <div id="bio">
              <a href="#bio">bio</a>
            </div>
            <div id="work">
              <a href="#work">work</a>
            </div>
            <div id="education">
              <a href="#education">education</a>
            </div>
            <div id="fun math">
              <a href="#fun_math">fun math</a>
            </div>
          </div>
        </div>
      </header>

      <ProjectGrid/>

      <div id="bio" className="App-bio">
        <Bio /> 
      </div>     
      
      <div id="work" className="App-work App-black-background">
        <Work /> 
      </div >    
      
      <div id="education" className="App-education App-gradient-background">
        <Education /> 
      </div>
      
      <div id="fun math" className="App-math">
        <FunMath />
      </div>
    </div>
  );
}

export default App;
