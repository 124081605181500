import "./Bio.css"
import im1 from "./images/IMG_0233.jpeg"
import im2 from "./images/FullSizeRender.jpeg"
import im3 from "./images/IMG_1201.jpeg"
import im4 from "./images/IMG_1331.jpeg"
import im5 from "./images/IMG_1461.jpeg"
import im6 from "./images/IMG_0051.jpeg"
import im7 from "./images/View recent photos.jpeg"

import React, { useState, useEffect } from 'react';


var images = [im1,im2,im3,im4,im5,im6,im7] // list of all image urls

function Bio() {
    const [currentImage,setCurrentImage] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
          setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
        }, 150000);
    });

    const handleImageClick = () => {
            setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    };

    return (
      <div className="bio-container">
         <div className="bio-images" onClick={handleImageClick}>
            <img className="bio-image" src={images[currentImage]}/>
         </div>
         <div className="bio-text">
            Hi I'm Alex, <br/><br/>I'm a complex problem-solver with an analytical mindset. My education in mathematics layed the foundation for my logical reasoning and my drive for knowledge. I have a genuine passion for utilizing technology to solve real-world problems. This can be seen via the many projects I have created, especially before I ever learned computer science in a formal setting.<br/><br/>I have worked in some software roles, and I learned the most from Amazon. While working at Amazon, my job required much away team work (building/maintining features in many code-bases). This experience taught me to learn quickly, as I frequently needed to onboard and familiarize myself with many new technologies. Additionally our team did not have any QA, so each engineer served as thier own testers, furthering my ability to write clean production ready code.
         </div>
      </div> 
    );
}

export default Bio;