import "./ProjectGrid.css"
import ProjectPane from "./ProjectPane"
import sherpaImage from './images/sherpa-icon.png';
import squaredIcon from "./images/squared-icon.png"
import groupifyIcon from "./images/groupify-icon.png"
import tdsIcon from "./images/TDS.png"
import React, { useState } from 'react';

const SHERPA_DATA = {
    hoverImage: sherpaImage,
    noHoverImage: sherpaImage,
    title: "Sherpa",
    dateFrom: "06/2023",
    dateTo: "01/2024",
    description: "iOS application build using SwiftUI,integrating AWS Amplify for seamless backend connectivity. Implemented geolocation functionality to provide users with information about nearby locations and features. Utilized AWS Lambda with NodeJS in conjunction with API Gateway for the backend architecture. Integration with multiple external APIs, including GooglePlaces and Chat GPT, enhancing the application's features and user experience.",
    linkText: "App Store",
    link: "https://apps.apple.com/us/app/sherpa-travel/id6473567436"
  }
  
  const SQUARED_DATA = {
    hoverImage: squaredIcon,
    noHoverImage: squaredIcon,
    title: "Squared",
    dateFrom: "06/2017",
    dateTo: "09/2017",
    description: "After my first year at UCSD I wanted to further my software abilities through development of a 'Flappy Bird-esque' game. Having no formal computer science education at this point I learned Swift to develop this iOS application. Enjoying the application of my new knowledge of Linear Algebra I created a game called Squared.",
    linkText: "",
    link: ""
  }
  
  const GROUPIFY_DATA = {
    hoverImage: groupifyIcon,
    noHoverImage: groupifyIcon,
    title: "Groupify",
    dateFrom: "07/2019",
    dateTo: "01/2020",
    description: "My self and a team created a fun music collaboration software solution. Multiple users could sign in to their spotify accounts and generate collaborative playlists based on their existing playlists and preferences. This project was great from a learning perspective because it was my first major use of existing API's and databases.",
    linkText: "GitHub",
    link: "https://github.com/AndrewKassab/groupify"
  }
  
  const CRAIGSLIST_ALARM = {
    hoverImage: "",
    noHoverImage: "",
    title: "Craigslist Alarm",
    dateFrom: "01/2016",
    dateTo: "02/2016",
    description: "I created a python script which would scrape Craigslist on intervals and notify users, via Facebook Messenger of new listings based on specific search criteria. A friend of mine was looking to purchase and resell vehichles and wanted fast notification of new listings. This was my first introduction to coding.",
    linkText: "",
    link: ""
  }
  
  const JAVA_TDS = {
    hoverImage: tdsIcon,
    noHoverImage: tdsIcon,
    title: "Java 3D Space",
    dateFrom: "01/2017",
    dateTo: "05/2017",
    description: "This project is best described as a reinvention of the wheel, for the sake of learning. While sitting in my Calculus C (gradients and vectors) I started thinking about the way that vision functions to project the 3 dimensional world onto a 2 dimensional plane. Through thinking and many drawings, I determined the calculation to translate any point in 3d space to the 2d coordinates in one's vision. I then implemented this as a 3D graph visualization tool. This was one of the most fun projects to me because it felt new, difficult, and suited my interests.",
    linkText: "GitHub",
    link: "https://github.com/argibson1/JavaGraphics"
  }
  

function ProjectGrid () {
    const [selectedProject, setSelectedProject] = useState(SHERPA_DATA);
    const [descriptionVisible, setDescriptionVisible] = useState(0);

    const handleClick = (data, row) => {
        if(data === selectedProject && descriptionVisible != 0) {
            setDescriptionVisible(0)
            setSelectedProject(null)
        } else {        
            setSelectedProject(data);
            setDescriptionVisible(row);
        }
    }

    var description1 = <div></div>, description2 = <div></div>;
    if (descriptionVisible !== 0) {
        if  (descriptionVisible === 1) {
            description1 = 
                <div id="project-description" className="project-grid-description" style={{padding: '2vw'}}>
                    <div className="project-description-header"><h1>{selectedProject.title}</h1><h3>{selectedProject.dateFrom} - {selectedProject.dateTo}</h3></div>
                    <div>
                        <p className="project-description-text">{selectedProject.description}</p>
                        <a href={selectedProject.link}>{selectedProject.linkText}</a>
                    </div>
        </div>  
        } else if (descriptionVisible === 2) {
            description2 = 
                <div id="project-description" className="project-grid-description" style={{padding: '2vw'}}>
                    <div className="project-description-header"><h1>{selectedProject.title}</h1><h3>{selectedProject.dateFrom} - {selectedProject.dateTo}</h3></div>
                        <div>
                            <p className="project-description-text">{selectedProject.description}</p>
                            <a href={selectedProject.link}>{selectedProject.linkText}</a>
                        </div>
                </div>   
        } 
    }

    return (
        <div className="project-grid-container">                
        
            <div className="project-grid">
                <ProjectPane data={SHERPA_DATA} handleClick={handleClick} row={1}/>
                <ProjectPane data={GROUPIFY_DATA} handleClick={handleClick} row={1}/>
                <ProjectPane data={SQUARED_DATA} handleClick={handleClick} row={1}/>
            </div>
            {description1}
            
            <div className="project-grid">
                <ProjectPane data={CRAIGSLIST_ALARM} handleClick={handleClick} row={2}/>
                <ProjectPane data={JAVA_TDS} handleClick={handleClick} row={2}/>
            </div>
            {description2}
        </div>
    );
}

export default ProjectGrid;