import './Job.css'

function Job ({jobData}) {
    return (
        <div className="job-container">
            <div className="job-header">
                <h1 className="job-company">{jobData.company}</h1>
                <span className="job-date">{jobData.timeRange}</span>
            </div>
            <div className="job-title-container">
                <h3 className="job-title">{jobData.title}</h3>
            </div>
            <div className='job-description'>
                <p className='job-description-text'>{jobData.description}</p>
            </div>
        </div>
    );
}

export default Job;