import './FunMath.css'

function FunMath () {
    return (
        <div>

        </div>
    );
}

export default FunMath;
